'use client'
import 'antd-mobile/es/global'
import React from 'react'
import MobilePage from './page_mobile'
import useWindowSize from 'hooks/useWindowSize'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

const Page = () => {
  const { width } = useWindowSize()
  const isMobile = width < 767

  if (isMobile) {
    return <MobilePage />
  }
}

export default Page
