'use client'
import React from 'react'
import Link from 'components/Link'
import style from './index.module.scss'
import Image from 'next/image'
import { getValueById } from 'helpers/config/getValueById'
import { getLang, getCountryKey } from 'helpers/country'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { useFirstRender } from 'helpers/useFirstRender'

import { useCompanyDetail } from './CompanyDetailsProvider'
import CompanyAlbum from './components/CompanyAlbum'
import CompanyJobList from './components/CompanyJobList'
import { setCookie } from 'helpers/cookies'

const visitPageKey = 'visitedPage'

const MobilePage = () => {
  const { detail, config } = useCompanyDetail()

  const benefits = detail.benefits || []
  const langKey = getLang()
  const isSg = getCountryKey() === 'sg'
  const langDicH5 = useSharedData('DICTIONARY')?.h5 || {}

  const firstRender = useFirstRender()
  if (firstRender) {
    // @ts-ignore
    isSg && window?.gtag?.('event', 'sgh5_active_page_event')
    const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000)
    setCookie(visitPageKey, 'sg-h5', oneHourFromNow)
  }

  return (
    <div className={style.container}>
      <div className={style.mobileHeader}>
        <div className={style.info}>
          <div className={style.logo}>
            <Image width={56} height={56} src={detail.logo_url} alt='_' />
          </div>
          <h1 className={style.companyName}>{detail.name}</h1>
        </div>
        <div className={style.subtitle}>
          <span className={style.item}>
            {getValueById(config, detail?.company_size_id, 'company_size_id') ||
              detail?.company_size}
          </span>
          {/* <span>{langDicH5?.employees}</span> */}
          <svg
            className={style.itemRightIcon}
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'
          >
            <path
              d='M7.16032 1.62321C7.62686 1.16626 8.37314 1.16626 8.83968 1.62321L10.0126 2.77204C10.2338 2.98871 10.5302 3.11148 10.8398 3.11469L12.4816 3.13173C13.1346 3.13851 13.6623 3.66621 13.6691 4.31921L13.6861 5.96095C13.6893 6.27057 13.8121 6.56697 14.0287 6.78817L15.1776 7.96111C15.6345 8.42764 15.6345 9.17392 15.1776 9.64046L14.0287 10.8134C13.8121 11.0346 13.6893 11.331 13.6861 11.6406L13.6691 13.2824C13.6623 13.9354 13.1346 14.4631 12.4816 14.4698L10.8398 14.4869C10.5302 14.4901 10.2338 14.6129 10.0126 14.8295L8.83968 15.9784C8.37314 16.4353 7.62686 16.4353 7.16032 15.9784L5.98739 14.8295C5.76618 14.6129 5.46979 14.4901 5.16017 14.4869L3.51843 14.4698C2.86543 14.4631 2.33773 13.9354 2.33095 13.2824L2.31391 11.6406C2.3107 11.331 2.18793 11.0346 1.97126 10.8134L0.822426 9.64046C0.365477 9.17392 0.365478 8.42764 0.822427 7.9611L1.97126 6.78817C2.18793 6.56697 2.3107 6.27057 2.31391 5.96095L2.33095 4.31921C2.33773 3.66621 2.86543 3.13851 3.51843 3.13173L5.16017 3.11469C5.46979 3.11148 5.76618 2.98871 5.98739 2.77204L7.16032 1.62321Z'
              fill='#2378E5'
            />
            <path
              d='M5.23438 8.49069L7.26933 10.5367L10.7649 7.06445'
              stroke='white'
              strokeWidth='1.8'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <Link to={'/' + langKey + detail?.company_url || '/'}>
            <span
              className={style.more}
              onClick={() => {
                // @ts-ignore
                isSg && window?.gtag?.('event', 'sgh5_company_detail_event')
              }}
            >
              {langDicH5?.more}
              <svg
                className={style.moreIcon}
                xmlns='http://www.w3.org/2000/svg'
                width='6'
                height='8'
                viewBox='0 0 6 8'
                fill='none'
              >
                <path
                  d='M5.4718 3.40204C5.73665 3.6021 5.73665 3.99994 5.4718 4.19999L1.26237 7.37955C0.93297 7.62836 0.461008 7.39338 0.461008 6.98057L0.461009 0.621464C0.461009 0.208656 0.932971 -0.0263202 1.26237 0.22249L5.4718 3.40204Z'
                  fill='#71645A'
                />
              </svg>
            </span>
          </Link>
        </div>
        <div className={style.tagGroup}>
          {benefits.map((item) => {
            return (
              <span key={item.id} className={style.tagItem}>
                {getValueById(config, item.id, 'company_benefit_id')}
              </span>
            )
          })}
        </div>
        <CompanyAlbum {...detail} />
      </div>
      {/* <div className={style.mobileHeaderBottomBorder}></div> */}
      <CompanyJobList
        config={config}
        salaryList={config.salary_range_filters}
        salaryTypeLists={config.salary_type_lists}
        dailySalaryRangeFilters={config.daily_salary_range_filters}
        hourlySalaryRangeFilters={config.hourly_salary_range_filters}
      />
    </div>
  )
}

export default MobilePage
