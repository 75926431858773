import * as React from 'react'
import style from './index.module.scss'
interface SectionProps extends React.PropsWithChildren {
  split?: boolean
  title: string
  hot?: boolean
  id?: string
  onHandelClose?: () => void
}

export default function Section(props: SectionProps) {
  const { onHandelClose = () => {} } = props
  return (
    <section className={style.section}>
      <div className={style.title_layout}>
        <div className={style.title}>
          {props.title}
          {props.id === 'Business information' ? (
            <span onClick={onHandelClose}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewBox='0 0 25 25'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M19.5605 12.9082C19.5605 17.0503 16.2027 20.4082 12.0605 20.4082C7.91841 20.4082 4.56055 17.0503 4.56055 12.9082C4.56055 8.76607 7.91841 5.4082 12.0605 5.4082C16.2027 5.4082 19.5605 8.76607 19.5605 12.9082ZM21.0605 12.9082C21.0605 17.8788 17.0311 21.9082 12.0605 21.9082C7.08998 21.9082 3.06055 17.8788 3.06055 12.9082C3.06055 7.93764 7.08998 3.9082 12.0605 3.9082C17.0311 3.9082 21.0605 7.93764 21.0605 12.9082ZM12.0606 8.70335C12.4748 8.70335 12.8106 9.03914 12.8106 9.45335V13.7317C12.8106 14.146 12.4748 14.4817 12.0606 14.4817C11.6463 14.4817 11.3106 14.146 11.3106 13.7317V9.45335C11.3106 9.03914 11.6463 8.70335 12.0606 8.70335ZM12.0606 17.1571C12.6453 17.1571 13.1194 16.683 13.1194 16.0983C13.1194 15.5135 12.6453 15.0394 12.0606 15.0394C11.4758 15.0394 11.0017 15.5135 11.0017 16.0983C11.0017 16.683 11.4758 17.1571 12.0606 17.1571Z'
                  fill='#FF9500'
                />
              </svg>
            </span>
          ) : null}
        </div>
        {props.hot && (
          <div className={style.tag}>
            <span>HOT</span>
          </div>
        )}
      </div>
      <div className={style.section_content_wrapper}>{props.children}</div>
      {props.split && <div className={style.split}></div>}
    </section>
  )
}
