import * as React from 'react'
import style from './index.module.scss'
interface SectionProps extends React.PropsWithChildren {
  split?: boolean
  title?: string
  hot?: boolean
  id?: string
  onHandelClose?: () => void
}

export default function Section(props: SectionProps) {
  const { onHandelClose = () => { } } = props
  return (
    <section className={style.section}>
      {props.title ? <div className={style.title_layout}>
        <div className={style.title}>
          {props.title}
          {props.id === 'Business information' ? (
            <span onClick={onHandelClose}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 4 }}
            >
              <svg
                style={{ width: 18, height: 18 }}
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.95611 10.5C9.95611 10.5 9.95613 10.4992 8.99978 10.4657C8.04343 10.4323 8.04346 10.4314 8.04346 10.4314L8.04357 10.4283L8.04378 10.4231L8.04441 10.4095C8.04494 10.3989 8.04569 10.3855 8.04675 10.3693C8.04887 10.3369 8.05225 10.2935 8.05757 10.2406C8.06819 10.1351 8.08671 9.99017 8.119 9.81857C8.18274 9.47981 8.30436 9.01408 8.54031 8.53691C8.777 8.05826 9.14196 7.5421 9.70264 7.14544C10.2732 6.74176 10.9941 6.49951 11.8706 6.49951C13.6529 6.49951 14.8379 7.47782 15.247 8.78813C15.6298 10.0143 15.3026 11.459 14.3201 12.3948L14.3187 12.3962C13.5694 13.107 13.1402 13.5082 12.8945 13.7348V14.9999H10.9807V13.3093C10.9807 13.0209 11.1106 12.7487 11.3331 12.5674L11.3504 12.5523C11.3807 12.5255 11.4417 12.4709 11.5553 12.3664C11.782 12.1578 12.2083 11.76 13.0007 11.0084C13.434 10.5952 13.5946 9.91751 13.4201 9.35848C13.2717 8.88331 12.8538 8.41339 11.8706 8.41339C11.3643 8.41339 11.0349 8.5473 10.808 8.70786C10.5711 8.87543 10.3912 9.11159 10.2559 9.38525C10.1198 9.6604 10.0423 9.94698 9.99988 10.1725C9.97908 10.283 9.96778 10.3731 9.96184 10.4322C9.95888 10.4616 9.95729 10.4829 9.95652 10.4946L9.95595 10.5039L9.95603 10.5021L9.95611 10.5Z" fill="#B8B8B8" />
                <path d="M13 18V16H11V18H13Z" fill="#B8B8B8" />
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z" fill="#B8B8B8" />
              </svg>
            </span>
          ) : null}
        </div>
        {props.hot && (
          <div className={style.tag}>
            <span>{typeof props.hot === 'string' ? props.hot : 'HOT'}</span>
          </div>
        )}
      </div> : null}
      <div className={style.section_content_wrapper}>{props.children}</div>
      {props.split && <div className={style.split}></div>}
    </section>
  )
}
