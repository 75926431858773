import React from 'react'
import styles from './index.module.scss'
import { useSharedData } from 'bossjob-remote/dist/hooks'
interface initProps {
  isShowTips: boolean
  onClose: () => void,
  title: string,
  content: string
}

const BusinessInformationExplanation = ({ isShowTips, onClose, title, content }: initProps) => {
  const langDic = useSharedData('DICTIONARY') || {}
  return (
    <>
      {isShowTips && (
        <>
          <div className={styles.mask}></div>
          <div className={styles.container}>
            <div className={styles.title}>
              {
                title || langDic?.business_Info_explanation
              }
              <span onClick={onClose}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.346 3.21626C13.5376 3.0247 13.5376 2.71413 13.346 2.52257C13.1545 2.33102 12.8439 2.33102 12.6523 2.52257L7.99918 7.17569L3.34602 2.52257C3.15447 2.33102 2.84389 2.33102 2.65234 2.52257C2.46079 2.71413 2.46079 3.0247 2.65234 3.21626L7.3055 7.86937L2.3922 12.7826C2.20065 12.9742 2.20065 13.2847 2.3922 13.4763C2.58375 13.6679 2.89433 13.6679 3.08588 13.4763L7.99918 8.56305L12.9125 13.4763C13.104 13.6679 13.4146 13.6679 13.6062 13.4763C13.7977 13.2847 13.7977 12.9742 13.6062 12.7826L8.69287 7.86937L13.346 3.21626Z'
                    fill='#7D7D7D'
                  />
                </svg>
              </span>
            </div>
            <p>
              {
                content || langDic?.business_Info_explanation_details
              }
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default BusinessInformationExplanation
