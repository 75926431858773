'use client'
import React, { useState } from 'react'
import style from './index.module.scss'
import Section from '../Section/index'
import { useCompanyDetail } from '../../CompanyDetailsProvider'
import Image from 'next/image'
import useWindowSize from 'hooks/useWindowSize'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import Lightbox from 'react-image-lightbox'

const CompanyAlbum = (_props) => {
  const props = { ..._props }
  const { width } = useWindowSize()

  const isMobile = width < 767

  if (isMobile) {
    return (
      <div className={style.tab_content_wrapper}>
        {props.pictures?.length > 0 && <Section title=''>{MobileAlbum()}</Section>}
      </div>
    )
  }
}

export default CompanyAlbum


function MobileAlbum() {
  const { width } = useWindowSize()
  const { detail } = useCompanyDetail()
  const [isOpenLightbox, setOpenLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const res = detail.pictures
  if (!res?.length) return null
  return (
    <div>
      <Swiper
        spaceBetween={8}
        slidesPerView={2.8}
        loop={false}
        scrollbar={{ draggable: true }}
      >
        {res.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className={style.mobile_album}>
                <Image
                  style={{ objectFit: 'cover' }}
                  fill
                  src={item.url}
                  alt='album'
                  onClick={() => {
                    setPhotoIndex(index)
                    setOpenLightbox(true)
                  }}
                ></Image>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {isOpenLightbox && (
        <Lightbox
          mainSrc={res[photoIndex].url}
          nextSrc={res[(photoIndex + 1) % res.length].url}
          prevSrc={res[(photoIndex + res.length - 1) % res.length].url}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + res.length - 1) % res.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex) => (photoIndex + 1) % res.length)}
        />
      )}
    </div>
  )
}
