import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import style from './index.module.scss'
import { JobData, fetchJobsListReq, getIDFromKeyword } from '../../service'
import { fetchJobDetailService } from 'store/services/jobs/fetchJobDetail'
import { useCompanyDetail } from '../../CompanyDetailsProvider'
import { useParams } from 'next/navigation'
import { languageContext, useLanguage } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty_h5'
import { getCookie } from 'helpers/cookies'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import { uniq } from 'lodash-es'
import { useFirstRender } from 'helpers/useFirstRender'
import MaterialButton from 'components/MaterialButton'
import ShowSalary from 'app/components/ShowSalary'
import { cloneDeep } from 'lodash-es'
import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'app/components/mobile/theme'
import { CheckList, Divider, Popup } from 'antd-mobile'
// import { CheckOutline } from 'antd-mobile-icons';
import useChatNow from 'app/models/hooks/useChatNow'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { fetchChatDetailService } from 'store/services/jobs/fetchChatDetail'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'
import { formatTemplateString } from 'helpers/formatter'
import { getValueById } from 'helpers/config/getValueById'
import ConfirmModal from '../ConfirmModal'
import { getCountryKey } from 'helpers/country'
import { ReactComponent as UrgentIcon } from '../assets/icons/urgent.svg'
import { ReactComponent as ArrowdownIcon } from '../assets/icons/arrowdown.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import { addEventTag, sessionStorageWithExpiry } from 'helpers/utilities'
interface Props {
  salaryList: any
  // containerHight: string
  salaryTypeLists: any
  dailySalaryRangeFilters: any
  hourlySalaryRangeFilters: any
  config: any
}

const jobTypeGtag = {
  1: 'sgh5_jobType_filter_fullTime',
  2: 'sgh5_jobType_filter_partTime',
  3: 'sgh5_jobType_filter_contract',
  4: 'sgh5_jobType_filter_temp',
  5: 'sgh5_jobType_filter_internship',
  6: 'sgh5_jobType_filter_freelance'
}

const JobCardList = (props: Props) => {
  const langDic = useSharedData('DICTIONARY') || {}
  const langDicH5 = langDic?.h5 || {}
  const isSg = getCountryKey() === 'sg'

  const contextLang = useContext(languageContext)
  let { location_lists, job_types } = props.config
  location_lists = location_lists.map((item) => item.locations).flat()

  const params = useParams()
  const { jobs, dictionary } = useCompanyDetail()
  const [jobsData, setJobsData] = useState(jobs)

  const newTheme = cloneDeep(theme)
  newTheme.components.MuiPaper.styleOverrides.root['height'] = 'calc(100% - 64px)'

  const [location, setLocation] = useState({ locationId: '', locationName: '' })
  const [jobType, setJobType] = useState({ jobTypeId: '', jobTypeName: '' })
  const [showPopupType, setShowPopupType] = useState('')

  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)
  const firstRender = useFirstRender()
  const pageRef = useRef(1)
  const [searchParams, setSearchParams] = useState<any>({
    companyIds: id,
    work_arrangement_ids: '1,2',
    size: 10,
    page: 1,
    query: inputText.current,
    location: '',
    jobTypeId: ''
  })

  const scrollRef = useRef(null)

  useEffect(() => {
    if (jobs.jobs?.length > 0) {
      getRecruiterIsOnline(jobs)
    }
  }, [jobs])

  useEffect(() => {
    if (firstRender) return
    searchFunc()
  }, [searchParams])

  const getRecruiterIsOnline = (oldData) => {
    const Ids = uniq(oldData.jobs.map((e) => e.recruiter_id))
    fetchQueryOnlineStatus({
      ids: Ids
    })
      .then((res) => {
        const data = res.data.data
        oldData.jobs.map((e) => {
          data.map((k) => {
            if (e.recruiter_id == k.user_id) {
              e.recruiter_is_online = k.is_online
            }
          })
        })
        setJobsData({ ...oldData })
      })
      .catch((e) => console.log(e))
  }

  const searchFunc = () => {
    setLoading(true)
    const newParams = cloneDeep(searchParams)
    fetchJobsListReq(newParams)
      .then((res) => {
        const data = res.data
        if (newParams.page > 1) {
          data.jobs = jobsData.jobs.concat(data.jobs)
        }
        setJobsData(data)
        getRecruiterIsOnline(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleShowPopupType = (popType) => {
    setTimeout(() => {
      setShowPopupType(popType)
    }, 500)
  }

  const handleSelectLocation = (value) => {
    const curSelectLocation = value[0] || ''
    const curLocation = location_lists.find((item) => item.id === curSelectLocation)
    setLocation({ ...location, locationId: curSelectLocation, locationName: curLocation?.value })
    setSearchParams({ ...searchParams, location: curSelectLocation, page: 1 })
    pageRef.current = 1
    handleShowPopupType('')
    // @ts-ignore
    isSg && window?.gtag?.('event', 'sgh5_location_filter')
  }

  const handleSelectJobType = (value) => {
    const curSelectJobType = value[0] || ''
    const curJobType = job_types.find((item) => item.id === curSelectJobType)
    setJobType({ ...jobType, jobTypeId: curSelectJobType, jobTypeName: curJobType?.value })
    setSearchParams({ ...searchParams, jobTypeId: curJobType?.id, page: 1 })
    pageRef.current = 1
    handleShowPopupType('')
    // @ts-ignore
    isSg && window?.gtag?.('event', jobTypeGtag[curJobType?.id])
  }

  return (
    <ThemeProvider theme={newTheme}>
      <div className={style.job_card_list}>
        <div className={style.job_card_list_filter}>
          <div className={style.filter_item} onClick={() => handleShowPopupType('location')}>
            <span
              className={classNames({
                [style.filter_name]: true,
                [style.filter_name_active]: !!location.locationName
              })}
            >
              {location.locationName || langDicH5.location}
            </span>
            <ArrowdownIcon />
          </div>
          <div className={style.filter_item} onClick={() => handleShowPopupType('jobType')}>
            <span
              className={classNames({
                [style.filter_name]: true,
                [style.filter_name_active]: !!jobType.jobTypeName
              })}
            >
              {jobType.jobTypeName || langDicH5.jobType}
            </span>
            <ArrowdownIcon />
          </div>
        </div>
        <div className={style.job_card_list_wrapper} ref={scrollRef}>
          <div className={style.content_layout}>
            {!!jobsData?.jobs.length ? (
              jobsData?.jobs.map((item, index) => {
                return <JobCard {...item} key={item.job_title + item.id + index} />
              })
            ) : (
              <div className={style.noData}>
                <Empty lang={contextLang.search} description={null} />
              </div>
            )}
          </div>
          <div className={style.pagination}>
            {(jobsData?.total_pages > pageRef.current || loading) && (
              <MaterialButton
                variant='contained'
                sx={{
                  height: '44px',
                  borderRadius: '4px',
                  textTransform: 'capitalize !important',
                  background: '#ffffff',
                  boxShadow: 'none',
                  border: '1px solid #2378E5',
                  color: '#2378E5',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  ':hover': {
                    backgroundColor: '#fff',
                    boxShadow: 'none'
                  },
                  '-loading': {
                    border: 'none'
                  }
                }}
                isLoading={loading}
                onClick={() => {
                  pageRef.current++
                  setSearchParams({
                    ...searchParams,
                    page: pageRef.current
                  })
                }}
              >
                {langDicH5.seeMore &&
                  formatTemplateString(langDicH5.seeMore, {
                    more: jobsData?.total_num - jobsData?.page * jobsData?.size
                  })}
              </MaterialButton>
            )}
            {jobsData?.total_pages === pageRef.current && !loading ? (
              <div>{langDicH5.noMore}</div>
            ) : null}
          </div>
        </div>
      </div>
      <Popup
        visible={!!showPopupType}
        showCloseButton={false}
        // onClose={() => {
        //   handleShowPopupType('')
        // }}
        onMaskClick={() => {
          handleShowPopupType('')
        }}
        bodyStyle={{ height: '50vh', borderRadius: '16px 16px 0px 0px' }}
      >
        <div style={{ padding: '20px' }}>
          {showPopupType == 'location' ? (
            <>
              <p style={{ marginBottom: 8, fontSize: 17, fontWeight: 700, color: '#121212' }}>
                {langDicH5.location}
                <span
                  className={style.close_icon}
                  onClick={() => {
                    handleShowPopupType('')
                  }}
                >
                  <CloseIcon />
                </span>
              </p>
              <CheckList
                defaultValue={[location.locationId]}
                onChange={(value) => handleSelectLocation(value)}
                className={style.job_card_check_list}
              >
                {location_lists.map((item) => {
                  return (
                    <CheckList.Item key={item.id} value={item.id}>
                      {item.value}
                    </CheckList.Item>
                  )
                })}
              </CheckList>
            </>
          ) : null}
          {showPopupType == 'jobType' ? (
            <>
              <p style={{ marginBottom: 8, fontSize: 17, fontWeight: 700, color: '#121212' }}>
                {langDicH5.jobType}
                <span
                  className={style.close_icon}
                  onClick={() => {
                    handleShowPopupType('')
                  }}
                >
                  <CloseIcon />
                </span>
              </p>
              <CheckList
                defaultValue={[jobType.jobTypeId]}
                onChange={(value) => handleSelectJobType(value)}
                className={style.job_card_check_list}
              >
                {job_types.map((item) => {
                  return (
                    <CheckList.Item key={item.id} value={item.id}>
                      {item.value}
                    </CheckList.Item>
                  )
                })}
              </CheckList>
            </>
          ) : null}
        </div>
      </Popup>
    </ThemeProvider>
  )
}

enum RemoteWorldwideType {
  OnSite = 1,
  Hybrid = 2,
  Remote = 3
}

const translateCountries = (countries, config) => {
  const country_list = config?.country_lists || []
  const result = countries.map((item) => {
    return country_list.find((country) => country.id === item.id)?.value
  })
  return result
}

export const getLocation = (props, lang, translations) => {
  const {
    job_region_id,
    job_location_id,
    work_arrangement_id,
    remote_countries = [],
    is_remote_worldwide,
    is_all_location
  } = props

  const { config } = useCompanyDetail()

  // remote work and not worldwide
  if (work_arrangement_id === RemoteWorldwideType.Remote && !is_remote_worldwide) {
    return translateCountries(remote_countries, config).join(',')
  }
  // remote work and worldwide
  // if (work_arrangement_id === RemoteWorldwideType.Remote && is_remote_worldwide) {
  //   return lang.global
  // }
  // on site and hybrid
  const location_list = config?.location_lists || []
  const region = location_list.find((item) => item.id === job_region_id)?.locations || []
  const location = region.find((item) => item.id === job_location_id)?.value || ''
  return `${getSingaporeAddress(is_all_location, translations, location)}` // `${location}`
}

const JobCard = (props: JobData) => {
  const userInfo = getCookie('user')
  const { lang, config, dictionary } = useCompanyDetail()
  const job_type_list = config?.job_types || []
  const contextLang = useContext(languageContext)
  const dic = useLanguage()
  const { overview } = contextLang.companyDetail

  const [jobDetailData, setJobDetailData] = useState<any>({})
  const [showModalType, setShowModalType] = useState('')
  const isLogin = getCookie('sessionid') ? true : false
  const langDic = useSharedData('DICTIONARY') || {}
  const langDicH5 = langDic?.h5 || {}
  const header = langDic?.jobDetail?.header || {}
  const router = useRouter()
  const isSg = getCountryKey() === 'sg'

  const locationName = getLocation(props, overview, dic.home.allRegions)

  const handleShowModalType = (modalType) => {
    setTimeout(() => {
      setShowModalType(modalType)
    }, 500)
  }

  const fetchJobDetail = () => {
    return new Promise((resolve, reject) => {
      fetchJobDetailService({ jobId: props.id })
        .then((response) => {
          const jobDetailData = response.data.data
          resolve(jobDetailData)
          setJobDetailData(jobDetailData)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  useEffect(() => {
    fetchJobDetail()
  }, [props.id])
  const [loading, chatNow, changeJobModal, userData] = useChatNow(jobDetailData)

  const handleBtnEvent = useCallback(async () => {
    const isSg = getCountryKey() === 'sg'
    isSg && window?.gtag?.('event', 'sgh5_direction_chat_event')
    // if (!isLogin) {
    //   handleShowModalType('chatConfirm')
    //   return
    // }
    const fetchChatDetail = async () => {
      if (jobDetailData?.recruiter_id) {
        const chatResponse = await fetchChatDetailService({
          recruiterId: jobDetailData?.recruiter_id,
          status: 'protected'
        })
        const chatData = chatResponse?.data?.data
        jobDetailData['chat'] = chatData
      }
    }

    // await fetchChatDetail()
    // addEventTag('sgh5_direction_success_chat')
    sessionStorage.setItem('openChatFrom', 'h5')
    sessionStorageWithExpiry.setItem('sg-h5-chat-from', 'job-card', 60)
    ;(chatNow as any)(jobDetailData).catch((err) => {
      console.log('--err--', err)
    })
  }, [jobDetailData, chatNow, postNotification])

  const handleGetChatNowElement = useCallback(() => {
    return (
      <MaterialButton
        variant='contained'
        sx={{
          background: '#2378E5',
          border: '1px solid #136FD3',
          borderRadius: '28px',
          fontWeight: '400',
          padding: '0 10px',
          height: '28px',
          lineHeight: '28px',
          color: '#ffffff',
          boxShadow: 'none',
          fontSize: '14px',
          '&:hover': {
            background: '#1B66C9',
            color: '#ffffff',
            boxShadow: 'none'
          }
        }}
        onClick={(e) => {
          e.stopPropagation()
          handleBtnEvent()
        }}
      >
        <span style={{ textTransform: 'none' }}>{langDicH5.chatAndApply}</span>
      </MaterialButton>
    )
  }, [handleBtnEvent, jobDetailData, header])

  const handleLogin = () => {
    // @ts-ignore
    isSg && window?.gtag?.('event', 'sgh5_comfirm_login_event')
    router.push(`/get-started?redirect=${window.location.pathname}`)
  }

  const handleGoTetail = () => {
    router.push('/' + lang + props.job_url + '?from=sg-h5')
    // @ts-ignore
    isSg && window?.gtag?.('event', 'sgh5_job_detail')
  }

  return (
    <>
      <div className={style.job_card}>
        <div className={style.job_card_wrapper} onClick={() => handleGoTetail()}>
          <div className={style.job_title}>
            {!props.is_urgent && (
              <span className={style.urgent_label}>
                <UrgentIcon />
                URGENT
              </span>
            )}
            <span>{props.job_title}</span>
          </div>
          <div className={style.job_type}>
            {locationName}
            {locationName && <Divider direction='vertical' style={{ borderColor: '#B8B8B8' }} />}
            {getValueById(config, props.job_type_id, 'job_type_id')}
          </div>
          <div className={style.job_benefits}>
            {props.job_benefits.map((item, index) => {
              return (
                <span className={style.job_tag} key={index}>
                  {item.name}
                  {/* {getValueById(config, item.id, 'job_benefit_id', 'name')} */}
                </span>
              )
            })}
          </div>
          {
            <div className={style.jobcard_salary_wrapper}>
              <div className={style.salary}>
                <ShowSalary
                  dictionary={{
                    salaryType: dictionary?.salaryType
                  }}
                  jobs={props}
                  size={14}
                />
              </div>
              <div className={style.chat_now}>
                {userInfo?.id == props?.id
                  ? props.external_apply_url
                    ? handleGetChatNowElement()
                    : null
                  : handleGetChatNowElement()}
              </div>
            </div>
          }
        </div>
      </div>
      {changeJobModal}
      <ConfirmModal
        visible={showModalType === 'chatConfirm'}
        onClose={() => setShowModalType('')}
        onCancel={() => setShowModalType('')}
        onConfirm={() => handleLogin()}
        title={langDicH5?.loginConfirmTitle}
        cancelText={langDicH5?.cancelLogin}
        confirmText={langDicH5?.confirmLogin}
      />
    </>
  )
}

export default JobCardList
