export const Salary = ({ salaryType, salaryRange, typeSize = 14 }) => {
  return <>
    {salaryRange}
    {
      salaryType &&
      <span
        style={{
          color: '#2378e5',
          fontSize: typeSize,
          letterSpacing: '0.12px',
          fontWeight: '400'
        }}
      >
        {`[${salaryType}]`}
      </span>
    }
  </>
}