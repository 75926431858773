import React from 'react'
import styles from './index.module.scss'
import JobCardList from '../JobCardList'

const CompanyJobList = ({
  config,
  salaryList,
  salaryTypeLists,
  dailySalaryRangeFilters,
  hourlySalaryRangeFilters
}: any) => {

  return (
    <>
      <div
        className={styles.company_job_list}
        id='company-job-list'
      >       
        <div className={styles.company_job_list_wrap}>
          <JobCardList
            config={config}
            salaryList={salaryList}
            salaryTypeLists={salaryTypeLists}
            // containerHight={containerHight}
            dailySalaryRangeFilters={dailySalaryRangeFilters}
            hourlySalaryRangeFilters={hourlySalaryRangeFilters}
          />
        </div>
      </div>
    </>
  )
}

export default CompanyJobList
