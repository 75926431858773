import React, { useContext, useMemo, useRef, useState } from 'react'
import style from './index.module.scss'
import { Country, JobClasses, getIDFromKeyword } from '../../service'
import Autocomplete from '@mui/material/Autocomplete'
import { flat } from 'helpers/formatter'
import Pagination from '@mui/material/Pagination'
import { useCompanyDetail } from '../../DataProvider'
import { findLastIndex } from 'lodash-es'
import Link from 'components/Link'
import { useParams } from 'next/navigation'
import Image from 'next/image'
import Loading from 'app/components/loading'
import className from 'classnames'
import { InView } from 'react-intersection-observer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
// import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
import { getCountryKey } from 'helpers/country'
import search_url from './search.svg'
import { fetchCompanyJobs } from '../../../service'
import { Salary } from '../Salary'
import { convertClientToBackendLang } from 'helpers/country.base'
import { getJobLocation } from '../../helper/getJobLocation'

interface Props {
  dictionary: any
}

const formatLocationConfig = (locationList) => {
  const locationConfig = (locationList || []).map((region) => region.locations)
  return locationConfig
}

export const JobsSearchCard = (props: any) => {
  const { lang, dictionary } = useCompanyDetail()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const contextLang = useContext(languageContext)
  const { item, isOnLine } = props
  const { overview } = contextLang.companyDetail

  const locations = getJobLocation(item)

  return (
    <div className={style.search_card}>
      <div className={style.search_title_layout}>
        <Link
          href={'/' + lang + item.job_url}
          target='_blank'
          title={item.job_title}
          className={style.title}
        >
          {item.is_urgent && (
            <span className={style.urgentLabel}>{dictionary?.search?.urgent}</span>
          )}
          <span style={{ verticalAlign: 'bottom' }}>{item.job_title}</span>
        </Link>
      </div>
      <div className={style.jobcard_salary_wrapper}>
        <div className={style.salary}>
          <Salary salaryType={item.salary_info.type_text} salaryRange={item.salary_info.text} />
        </div>
        <Link
          className={style.chat_now}
          href={'/' + lang + item.job_url + '?source=company_detail'}
          target='_blank'
        >
          {overview.jobs.card.chatNow}
        </Link>
      </div>

      <div className={style.content}>
        {item.job_tags
          .map((tag, index) => {
            if (!tag) return null
            return (
              <span className={style.mobile_tag} key={index}>
                {tag}
              </span>
            )
          })
          .slice(0, 3)}
        {!isMobile && <JobsTag data={item.job_tags} />}
      </div>
      <div className={style.footer}>
        <div className={style.chat_footer}>
          <div className={style.avatar}>
            <Image fill src={item.recruiter.avatar} alt='img' />
            <div
              className={style.status}
              style={{ backgroundColor: isOnLine ? '#0ebd5c' : '#E5E6EB' }}
            />
          </div>
          <Link
            className={style.name}
            href={'/' + lang + item.job_url + '?source=company_detail'}
            target='_blank'
          >
            <span title={item.recruiter.full_name}>{item.recruiter.full_name}</span>
            {item.recruiter.job_title && (
              <>
                &nbsp;<div style={{ position: 'relative', top: -2 }}>.</div>&nbsp;
                <span title={item.recruiter.job_title}>{item.recruiter.job_title}</span>
              </>
            )}
          </Link>
        </div>
        <div className={style.location} title={locations}>
          {locations}
        </div>
      </div>
    </div>
  )
}

interface TagProps {
  count?: number
  classNames?: any
  type?: 'background'
  style?: React.CSSProperties
  data?: any[]
  showTitle?: boolean
}

export const JobsTag = (props: TagProps) => {
  const { data, count = 3, showTitle } = props
  return (
    <div className={style.tags} style={props.style ? props.style : null}>
      {(data || [])?.slice(0, count)?.map((item, index) => {
        if (!item) return null

        return (
          <div
            className={style.tag_item + ' ' + ' tag_flag'}
            key={index}
            title={showTitle ? item : undefined}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const JobList = ({ data, contextLang }) => {
  return Boolean(data?.length) ? (
    data.map((item, index) => {
      // console.log('item', item)
      return (
        <JobsSearchCard
          isOnLine={Boolean(item.recruiter.is_online)}
          item={item}
          {...item}
          key={item.job_title + item.id + index}
        />
      )
    })
  ) : (
    <div className={style.noData}>
      <Empty lang={contextLang.search} description={null} />
    </div>
  )
}

const SearchPanel = (props: Props) => {
  const { jobs, lang } = useCompanyDetail()

  const { search_info, company_jobs = {} } = jobs as any
  const formattedLocationList = flat(formatLocationConfig(search_info?.location_lists || []))
  const params = useParams()
  const [jobsData, setJobsData] = useState(company_jobs)
  const [page, setPage] = useState(1)

  const [location, setLocation] = useState<Country | undefined>()
  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const [classes, setClasses] = useState<JobClasses | undefined>()
  const id = getIDFromKeyword(params.keyword as any)

  const [offset, setOffset] = useState(0)
  const [leftShow, setLeftShow] = useState(false)
  const [rightShow, setRightShow] = useState(true)
  const firstRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useMediaQuery('(max-width:768px)')
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const currentLocation = useRef<Country>()
  const containerRef = useRef(null)

  const jobFunctionArray = useMemo(() => {
    let result = []
    ;((jobs as any)?.function_tags || []).map((v) => {
      result.push({ id: v.id, value: v.value })
    })
    return result
  }, [])

  const searchFunc = (jobTitle?: string, location?: Country, page = 1, job_function_ids?: any) => {
    setLoading(true)
    const reqData = {
      companyIds: id,
      size: 10,
      page,
      query: jobTitle || inputText.current,
      location_id: location?.id,
      job_function_id: job_function_ids ? String(job_function_ids) : classes?.id
    }
    if (job_function_ids === 'all') {
      delete reqData.job_function_id
    }
    Object.keys(reqData).forEach((key) => {
      if (reqData[key] === null || reqData[key] === undefined) {
        delete reqData[key]
      }
    })

    fetchCompanyJobs(id, convertClientToBackendLang(lang), reqData)
      .then((res) => {
        // console.log('res', res)
        const { company_hot_job, job_nums, page } = res.company_jobs
        setJobsData({ company_hot_job, job_nums })
        setPage(page)
        setLoading(false)
      })
      .catch(() => {
        setJobsData({ company_hot_job: [], job_nums: 0 })
        setLoading(false)
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchFunc(null, location)
    }
  }
  const previousFunction = () => {
    const all = document.getElementsByClassName('search-filter-tag')
    const previousindex = [...all].findIndex((item) => item.getAttribute('data-visible') === 'true')
    try {
      // @ts-ignore
      const { offsetWidth } = all[previousindex - 1]
      setOffset((_) => _ - offsetWidth)
    } catch (e) {}

    // const index = array.reverse().findIndex(item => item.visible);
  }
  const nextFunction = () => {
    const all = document.getElementsByClassName('search-filter-tag')
    const index = findLastIndex([...all], (item, index) => {
      return item.getAttribute('data-visible') === 'true'
    })

    try {
      // @ts-ignore
      const { offsetWidth } = all[index + 1]
      setOffset((_) => offsetWidth + _)
    } catch (e) {
      console.log('list of filter tag is end')
    }
  }

  const AutocompleteComponent = useMemo(() => {
    const isSingle = /^(sg|us|ca|ng|lk|br|ae)$/.test(getCountryKey())
    return (
      <Autocomplete
        id='location-autocomplete1'
        options={formattedLocationList}
        groupBy={isSingle ? undefined : (option: any) => option.region_display_name}
        getOptionLabel={(option: any) => {
          return option.value
        }}
        value={location}
        size='small'
        onChange={(e, value) => {
          currentLocation.current = value
          setLocation(value)
          searchFunc(inputText.current, value, 1)
        }}
        key={location?.id}
        // disablePortal
        disableClearable={false}
        // className={className}
        // disableCloseOnSelect
        renderInput={(params) => {
          return (
            <label
              ref={params.InputProps.ref}
              htmlFor={'location-autocomplete'}
              className={style.location_input_wrapper}
            >
              <input
                {...params.inputProps}
                placeholder={props.dictionary.comm.location || 'Location'}
                className={style.location_input}
              />
              <div className={style.location_arrow}></div>
              <div className={style.location_input_border} />
            </label>
          )
        }}
        // defaultValue={defaultValue}
        // {...rest}
      />
    )
  }, [location, getCountryKey])

  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          <div className={style.location_selector_wapper}>{AutocompleteComponent}</div>
          <label htmlFor='input-search' className={style.job_search_container}>
            <Image width={16} height={16} className={style.job_prefix} src={search_url} alt='_' />
            <input
              id={'input-search'}
              name={'input-search'}
              placeholder={search_info?.input_text || overview.SearchPlaceholder}
              className={style.job_search_input}
              onChange={(e) => {
                inputText.current = e.target.value
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={handleKeyPress}
            />
          </label>
        </div>
        <div
          className={className({
            [style.search_button]: true
            // [style.button_loading]: loading
          })}
          onClick={() => {
            if (loading) return
            searchFunc()
          }}
        >
          <span>
            {search_info?.search_text || overview.Find}
            {/* {isMobile ? overview.Find : overview['FindNow']} */}
          </span>
        </div>
      </div>
      {jobFunctionArray.length > 1 && (
        <div className={style.filter_container}>
          <div
            className={className({
              [style.arrow_left]: true,
              [style.opacity]: !leftShow
            })}
            onClick={previousFunction}
          ></div>
          <div className={style.filter_layout} ref={containerRef}>
            <div
              className={style.filter_wrapper}
              style={{
                transform: !isMobile ? `translate3d(${-offset}px, 0px, 0px)` : 'none'
              }}
            >
              <InView threshold={1}>
                {({ ref, inView }) => {
                  if (!isMobile) {
                    if (inView) {
                      setLeftShow(false)
                    } else {
                      setLeftShow(true)
                    }
                  }

                  return (
                    <div
                      className={className({
                        ['search-filter-tag']: true,
                        [style.filter_tag]: true,
                        [style.active]: !classes
                      })}
                      data-visible={inView}
                      ref={ref}
                      onClick={() => {
                        searchFunc(null, location, 1, 'all')
                        setClasses(undefined)
                        if (!inView) {
                          previousFunction()
                        }
                      }}
                    >
                      {jobFunctionArray[0].value}
                    </div>
                  )
                }}
              </InView>

              {jobFunctionArray.slice(1)?.map((item, index) => {
                return (
                  <InView threshold={1} key={item.id}>
                    {({ ref, inView }) => {
                      if (!isMobile) {
                        if (inView && jobFunctionArray.length - 1 === index + 1) {
                          setRightShow(false)
                        } else {
                          setRightShow(true)
                        }
                      }
                      return (
                        <div
                          ref={ref}
                          data-visible={inView}
                          className={className({
                            ['search-filter-tag']: true,
                            [style.filter_tag]: true,
                            [style.active]: item.id === classes?.id
                          })}
                          onClick={() => {
                            searchFunc(null, location, 1, item.id)
                            setClasses(item as any)

                            if (!inView) {
                              const all = document.getElementsByClassName('search-filter-tag')
                              const nextElement = all[index + 2]
                              if (nextElement) {
                                if (nextElement.getAttribute('data-visible') === 'false') {
                                  nextFunction()
                                } else {
                                  previousFunction()
                                }
                              } else {
                                nextFunction()
                              }
                            }
                          }}
                        >
                          {/* {inView ? '1' : '2'} */}
                          {item.value}
                        </div>
                      )
                    }}
                  </InView>
                )
              })}
            </div>
          </div>

          <div
            className={className({
              [style.arrow_right]: true,
              [style.opacity]: !rightShow
            })}
            onClick={nextFunction}
          ></div>
        </div>
      )}
      <div className={style.search_content_wrapper}>
        <div className={style.filter_split} ref={firstRef}></div>
        <div className={style.content_layout}>
          {loading ? (
            <div className={style.loading_wrapper}>
              <Loading />
            </div>
          ) : (
            <JobList data={jobsData?.company_hot_job} contextLang={contextLang} />
          )}
        </div>
        <div className={style.pagination}>
          {!!jobsData?.job_nums && !loading && (
            <Pagination
              page={page}
              count={Math.ceil(jobsData.job_nums / 10)}
              onChange={(e, v) => {
                if (isMobile) {
                  firstRef.current &&
                    firstRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest'
                    })
                }
                searchFunc(null, location, v)
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
              }}
              shape='rounded'
              color={'primary'}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default SearchPanel
