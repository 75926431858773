import React, { useState, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FormHelperText } from '@mui/material'
import styles from 'styles/maintenance.module.scss'
import classNames from 'classnames'
import { FormControl, IconButton, } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MaterialBasicSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  onOpen,
  greyBg,
  defaultValue,
  fieldRef,
  disabled,
  required,
  error,
  inputFontSize,
  multipleValue = [],
  checkRequired = false,
  useID = false,
  hiddenLabel = false,
  isShowFlag = false,
  test = false,

  ...rest
}: any) => {
  const [value, setValue] = useState(defaultValue || '1')
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
    if (onSelect) {
      onSelect(event.target.value)
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        overflow: 'auto'
      }
    }
  }

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            transform: 'translate(14px, 10px) scale(1)',
            letterSpacing: '1px',
            '&.Mui-focused': {
              fontSize: '10px',
              transform: 'translate(14px, -10px) scale(1)'
            },
            lineHeight: '10px',
            padding: '0 5px',
            background: '#fff'
          },
          shrink: {
            fontSize: '10px',
            transform: 'translate(14px, -10px) scale(1)'
          },
          outlined: {
            '&.MuiInputLabel-shrink': {
              fontSize: '10px'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            height: '30px',
            backgroundColor: greyBg ? '#E2E2E2' : 'white',
            lineHeight: '16px',
            alignItems: 'self-end',
            minWidth: '86px',
            width: 'auto',
            borderColor: 'transparent',
            '&-notchedOutline': {
              border: 'none'
            },
            '.MuiSelect-select': {
              padding: '2.5px 14.5px 8px 3px !important'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: inputFontSize || '14px',
            letterSpacing: '1px',
            paddingRight: '12px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            letterSpacing: '1px',
            padding: '10px 16px',
            borderBottom: '1px solid #E4E4E4',
            '&.Mui-selected': {
              backgroundColor: '#fff',
              color: '#2378E5',
              '&:hover': {
                backgroundColor: '#fff !important'
              }
            },
            '&:last-child': {
              borderBottom: 'none'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '6px'
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              boxShadow: 'rgba(0, 0, 0, 0.5)'
            },
            '&.MuiPopover-paper': {
              width: '100vw !important',
              left: '0 !important',
              maxWidth: '100vw',
              boxShadow: 'none',
              paddingBottom: '20px',
              top: 'unset !important',
              bottom: '0',
              maxHeight: 'calc(80vh - 162px) !important',
              height: 'calc(80vh - 162px) !important',
              li: {
                margin: '0 20px',
                padding: 0,
                backgroundColor: '#fff !important'
              }
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '.MuiSelect-select': {
              ':focus': {
                background: 'unset',
              },
              paddingRight: '12px'
            }
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '20px',
            display: 'none'
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            position: 'absolute',
            right: '2px',
            bottom: '2px'
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={classNames({
          [className]: true,
          [styles.hiddenLabel]: hiddenLabel
        })}
        size='small'
        error={!!error}
      >
        <Select
          displayEmpty
          value={value || 'emptyValue'}
          onChange={handleChange}
          input={<OutlinedInput />}
          sx={{
            paddingRight: '14px'
          }}
          endAdornment={
            <IconButton style={{
              pointerEvents: 'none'// not capture the user actions, so when user clicking on this root btn, click event will be dispatched
            }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='11'
                height='7'
                viewBox='0 0 11 7'
                fill='none'
              >
                <path
                  d='M0.938965 0.907986L5.40409 5.42093L9.67375 0.601135'
                  stroke='#414141'
                  strokeLinecap='round'
                />
              </svg>
            </IconButton>
          }
          renderValue={(selected: any) => {
            if (selected === 'emptyValue') {
              return (
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: 14,
                    position: 'relative',
                    top: 6,
                    width: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'right'
                  }}
                >
                  {label}
                </div>
              )
            } else {
              return (
                <div
                  style={{
                    position: 'relative',
                    fontSize: 14,
                    top: 6,
                    width: '60px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    color: '#2378E5',
                    textAlign: 'right'
                  }}
                >
                  {options.find((e) => e.id === selected)?.label}
                </div>
              )
            }
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options &&
            options.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}
export default MaterialBasicSelect
