'use client'

import React, { useContext, createContext, PropsWithChildren } from 'react'
import { CompanyDetailsType, JobsResponseType, Recruiter } from './service'
import { ConfigType } from 'app/types'
import { JobClasses } from './service'

type ProviderData = {
  detail: CompanyDetailsType
  jobs: JobsResponseType
  lang: string
  config: Partial<ConfigType>
  dictionary?: any
}

const CompanyDetails = createContext<ProviderData | undefined>(undefined)

interface Props extends PropsWithChildren<ProviderData> {
  name?: any
}
export function CompanyDetailsProvider({
  children,
  detail,
  jobs,
  lang,
  config,
  dictionary
}: Props) {
  return (
    <CompanyDetails.Provider
      value={{
        detail,
        jobs,
        lang,
        config,
        dictionary
      }}
    >
      {children}
    </CompanyDetails.Provider>
  )
}

export function useCompanyDetail() {
  const context = useContext(CompanyDetails)
  if (context === undefined) {
    throw new Error('useCartCount must be used within a CartCountProvider')
  }
  return context
}
