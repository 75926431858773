import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg'

interface ModalProps {
  visible: boolean;
  title?: string,
  cancelText?: string,
  confirmText?: string,
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ModalStyle = styled.div`
  z-index: 1001;
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    .modal-wrap {
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 32px 20px;
      .modal-content {
        margin-top: 20px;
        color: #121212;
        text-align: center;
        font-size: 16px;
      }
      .modal-btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      .modal-footer {
        display: flex;
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      .modal-btn {
        flex: 1;
        padding: 10px 24px;
        margin-left: 10px;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
      .modal-btn-cancel {
        border: 1px solid #E4E4E4;
        color: #121212;
      }
      .modal-btn-confirm {
        background-color: #2378E5;
      }
    }
  }
`;

const ConfirmModal: React.FC<ModalProps> = ({ visible, title, cancelText, confirmText, onClose, onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(visible);

  useEffect(() => {
    setIsOpen(visible); // 监听 visible 属性的变化，更新 isOpen 状态
  }, [visible]);

  const handleClose = () => {
    onClose && onClose()
    setIsOpen(false); // 关闭模态框时将 isOpen 设置为 false
  };

  const handleCancel = () => {
    onCancel && onCancel()
    setIsOpen(false);
    window?.gtag?.('event', 'sgh5_chat_login_confirm_later')
  };

  const handleConfirm = () => {
    onConfirm && onConfirm()
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <ModalStyle>
          <div className='modal-overlay' onClick={handleClose}>
            <div className='modal-wrap' onClick={(e) => e.stopPropagation()}>
              <InfoIcon />
              <p className='modal-content'>{title || 'You can apply for the job after login or register.'}</p>
              <div className='modal-footer'>
                <span className='modal-btn modal-btn-cancel' onClick={handleCancel}>{cancelText || 'Later'}</span>
                <span className='modal-btn modal-btn-confirm' onClick={handleConfirm}>{confirmText || 'Login'}</span>
              </div>
            </div>
          </div>
        </ModalStyle>
      )}
    </>
  );
};

export default ConfirmModal;