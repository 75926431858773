import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import style from './index.module.scss'
import { JobClasses, getIDFromKeyword } from '../../service'
import { useCompanyDetail } from '../../DataProvider'
import { useParams } from 'next/navigation'
import { useFirstRender } from 'helpers/useFirstRender'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
import { debounce } from 'lodash-es'
import MaterialButton from 'components/MaterialButton'
import MaterialBasicSelect from '../Select/MaterialBasicSelect'
import { JobsSearchCard } from '../SearchPanelMobile'
import { fetchCompanyRemoteJobs } from '../../../service'
import { convertClientToBackendLang } from 'helpers/country.base'
interface Props {
  functions: JobClasses[]
  locationRemote?: any
  languageRemote?: any
  remoteJobs?: any
  setOpen: (Boolean) => void
  containerHight: string
  addJobsTableTouch: any
}

const Search = () => {
  return <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
  >
    <path
      d='M8.75 15.511C12.2018 15.511 15 12.7128 15 9.26105C15 5.80928 12.2018 3.01105 8.75 3.01105C5.29824 3.01105 2.5 5.80928 2.5 9.26105C2.5 12.7128 5.29824 15.511 8.75 15.511Z'
      stroke='#B8B8B8'
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
    <path
      d='M13.3333 13.8444L16.6666 17.1777'
      stroke='#B8B8B8'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
}
export const SearchPanelRemoteMobile = (props: Props) => {
  const params = useParams()
  const { containerHight, addJobsTableTouch } = props
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const { remoteJobs, isRemoteWorldwide, lang, companyId } = useCompanyDetail();
  const [jobsData, setJobsData] = useState(remoteJobs?.company_remote_jobs?.company_hot_job || {})
  const [pagination, setPagination] = useState({
    page: 1,
    total: remoteJobs?.company_remote_jobs?.job_nums || 0
  })
  const onlineState = {}

  useEffect(() => {
    return addJobsTableTouch('remote-card-list')
  }, [])
  const jobFunctions = props.functions?.map((e) => ({
    label: e.value,
    id: e.id
  }))

  const locationRemoteLists = props.locationRemote?.map((e) => ({
    label: e.value,
    id: e.id
  }))
  if (isRemoteWorldwide) {
    locationRemoteLists?.unshift({ label: overview.worldwide, id: '-1' })
  }

  const languageRemoteLists = props.languageRemote?.map((e) => ({
    label: e.value,
    id: e.id
  }))

  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const pageRef = useRef(1)
  const [isMobileLoading, setIsMobileLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)
  const scrollRefRemote = useRef(null)
  // const filterTagView = useRef<JobVisible[]>([{}].concat(props.functions));
  const firstRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useMediaQuery('(max-width:768px)')

  const firstRender = useFirstRender()
  const [searchParams, setSearchParams] = useState({
    language_id: '',
    job_function_id: '',
    remote_country_id: ''
    // is_remote_worldwide: 1
  })

  const unshiftArr = (arr = []) => {
    let copy = [...arr].filter(v => Boolean(v.id))
    copy?.unshift({
      label: overview.All,
      id: ''
    })
    return copy
  }
  const debouncedFunction = useCallback(
    debounce((params) => {
      // 在这里执行你想要防抖的操作
      searchFunc(null, 1, params)
    }, 300),
    []
  )

  useEffect(() => {
    if (firstRender) return
    debouncedFunction(searchParams)
  }, [searchParams])

  const searchFunc = (jobTitle?: string, page = 1, params?: any) => {
    if (isMobile) {
      setIsMobileLoading(true)
    } else {
      setLoading(true)
    }

    const reqData = {
      size: 10,
      page,
      query: jobTitle || inputText.current,
      ...params
    }

    if (isRemoteWorldwide && params.remote_country_id === '-1') {
      reqData.is_remote_worldwide = 1
      delete reqData.remote_country_id
    }

    fetchCompanyRemoteJobs(id, convertClientToBackendLang(lang), reqData).then((res) => {
      const { company_hot_job = [], page, job_nums } = res.company_remote_jobs || {}

      if (reqData.page > 1) {
        setJobsData(pre => {
          return [...pre, ...(company_hot_job || [])]
        })
      } else {
        setJobsData(company_hot_job)
      }
      setPagination({ page, total: job_nums })
      // 
    }).catch((e) => {
      console.log(e)
      pageRef.current--;
    }).finally(() => {
      setLoading(false)
      setIsMobileLoading(false)
    })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchFunc(null, 1, searchParams)
      event?.target?.blur?.()
    }
  }

  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          <label htmlFor='input-search' className={style.job_search_container}>
            <span className={style.job_prefix}>
              <Search />
            </span>
            <input
              id={'input-search'}
              name={'input-search'}
              placeholder={overview.SearchPlaceholder}
              className={style.job_search_input}
              enterKeyHint='search'
              onChange={(e) => {
                inputText.current = e.target.value
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={handleKeyPress}
            />
          </label>
        </div>
      </div>
      <div className={style.select}>
        <MaterialBasicSelect
          label={overview.jobFunction}
          value={''}
          options={unshiftArr(jobFunctions)}
          onSelect={(e) => {
            pageRef.current = 1
            setSearchParams({
              ...searchParams,
              job_function_id: e
            })
          }}
        ></MaterialBasicSelect>
        <MaterialBasicSelect
          label={overview.location}
          options={unshiftArr(locationRemoteLists)}
          onSelect={(e) => {
            pageRef.current = 1
            setSearchParams({
              ...searchParams,
              remote_country_id: e
            })
          }
          }
        ></MaterialBasicSelect>
        <MaterialBasicSelect
          label={overview.language}
          options={unshiftArr(languageRemoteLists)}
          onSelect={(e) => {
            pageRef.current++;
            setSearchParams({
              ...searchParams,
              language_id: e
            })
          }
          }
        ></MaterialBasicSelect>
      </div>
      <div className={style.lineBox}></div>
      <div
        id='remote-card-list'
        className={style.search_content_wrapper}
        style={{ height: `calc(${containerHight} - 152px)` }}
        ref={scrollRefRemote}
      >
        <div className={style.filter_split} ref={firstRef}></div>
        <div className={style.content_layout}>
          {
            !!jobsData.length ? (
              !loading &&
              jobsData.map((item, index) => {
                return <JobsSearchCard
                  item={item}
                  isOnline={Boolean(onlineState[item.recruiter_full_name])}
                  {...item}
                  key={item.job_title + item.id + index}
                />
              })
            ) : (
              <div className={style.noData}>
                <Empty lang={contextLang.search} description={null} />
              </div>
            )}
        </div>
        <div className={style.pagination}>
          {(Math.ceil(pagination.total / 10) > pageRef.current || isMobileLoading) && (
            <MaterialButton
              variant='contained'
              sx={{
                height: '44px',
                borderRadius: '4px',
                width: '144px',
                textTransform: 'capitalize !important',
                background: '#ffffff',
                boxShadow: 'none',
                border: '1px solid #2378E5',
                color: '#2378E5',
                fontSize: '14px',
                fontWeight: 'normal',
                ':hover': {
                  backgroundColor: '#fff',
                  boxShadow: 'none'
                },
                '-loading': {
                  border: 'none'
                }
              }}
              isLoading={isMobileLoading}
              onClick={() => {
                pageRef.current++
                searchFunc('', pageRef.current, searchParams)
              }}
            >
              {contextLang?.home?.seeMoreBtn}
            </MaterialButton>
          )}
        </div>
      </div>
    </div >
  )
}



// SearchPanelexport default 
